var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('Header'),_c('div',{staticClass:"mx-auto mt-1 content-outer-container account-setting-page",class:{ 'content-dashboard': _vm.$store.state.isDashboard }},[_c('div',{staticClass:"mx-auto content-inner-container"},[_c('div',{staticClass:"wrap-AccountSetup"},[_c('div',{staticClass:"row paj-custom-row"},[_c('div',{staticClass:"col-12 custom-wrapper-dashboard"},[_c('div',{staticClass:"d-flex align-items-center flex-wrap mr-2 text-title"},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/accountsetup/setting.svg"),"alt":""}}),_vm._v(" "+_vm._s(_vm.lbl['main-menu-setting'])+" ")])]),_c('div',{staticClass:"col-xl-12 col-lg-12 col-md-12 wrapper-content-tab"},[_c('div',{staticClass:"d-flex wrapper-select-header"},[(_vm.isEdit)?_c('div',{staticClass:"select-header cursor-pointer",class:{ active: true }},[_vm._v(" "+_vm._s(_vm.lbl['account-setup-account-setting'])+" ")]):_c('div',{staticClass:"select-header",class:{ active: true }},[_vm._v(" "+_vm._s(_vm.lbl['account-setup-account-setting'])+" ")])])])]),_c('div',{staticClass:"px-5"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"row d-flex justify-content-between"},[_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12"},[_c('a-form-item',[_c('strong',{staticClass:"fz-16"},[_vm._v(_vm._s(_vm.lbl['account-setup-your-business-name']))]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'business_name',
                      {
                        initialValue: _vm.business_name,
                        rules: [
                          {
                            required: true,
                            message: '',
                          } ],
                      } ]),expression:"[\n                      'business_name',\n                      {\n                        initialValue: business_name,\n                        rules: [\n                          {\n                            required: true,\n                            message: '',\n                          },\n                        ],\n                      },\n                    ]"}],staticClass:"form-control w-100",attrs:{"type":"text"}})],1)],1),_c('div',{staticClass:"\n                  col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12\n                  d-flex\n                  justify-content-end\n                "},[_c('span',{staticClass:"mr-3"},[_vm._v(" "+_vm._s(_vm.lbl['setup-header-appid'])+" : "+_vm._s(_vm.appId)+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.lbl['setup-header-brandid'])+" : "+_vm._s(_vm.brandId)+" ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('a-form-item',{staticClass:"field-item"},[_c('strong',{staticClass:"fz-16"},[_vm._v(_vm._s(_vm.lbl['account-setup-upload-your-logo']))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 field-upload"},[_c('div',{staticClass:"box-img",class:{
                          'box-img-border': _vm.isPreview,
                          'none-border': _vm.imgPreview,
                        }},[(!_vm.imgPreview)?_c('img',{staticClass:"w-100",attrs:{"src":require("@/assets/images/blank-photo.png"),"alt":""}}):_c('img',{staticClass:"w-100 h-100",attrs:{"src":_vm.imgPreview,"alt":""}})]),_c('div',{staticClass:"box-upload"},[_c('div',{staticClass:"btn-bg-upload",on:{"click":function($event){return _vm.uploadArtWork($event)}}},[_c('input',{ref:"uploadImg",attrs:{"type":"file","accept":"image/jpeg, image/png, image/jpg","hidden":""},on:{"change":function($event){return _vm.onImgFileChange($event)}}}),_c('img',{attrs:{"src":require("@/assets/images/accountsetup/account_upload.svg"),"alt":"","width":"24","height":"24"}}),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.lbl['account-setup-upload-image'])+" ")])]),_c('div',{staticClass:"box-recommended"},[_c('div',[_vm._v(" "+_vm._s(_vm.lbl['account-setup-the-recommended'])+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.lbl['account-setup-upload-file-recommended'])+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.sizeUpload)+" ")])])])])])])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('hr'),_c('b-button',{ref:"submit",staticClass:"btn-submit float-right",attrs:{"variant":"warning","html-type":"submit","type":"submit","disabled":_vm.formState}},[_vm._v(" "+_vm._s(_vm.lbl['account-setup-button-save'])+" ")])],1)])])],1)])])]),_c('AlertModal',{attrs:{"isOpenModal":_vm.alertModalSetting.isOpenModal,"modalType":_vm.alertModalSetting.modalType,"title":_vm.alertModalSetting.title,"details":_vm.alertModalSetting.details}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }