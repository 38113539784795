<template>
  <div class="container-fluid">
    <Header />
    <div
      class="mx-auto mt-1 content-outer-container account-setting-page"
      :class="{ 'content-dashboard': $store.state.isDashboard }"
    >
      <div class="mx-auto content-inner-container">
        <div class="wrap-AccountSetup">
          <div class="row paj-custom-row">
            <div class="col-12 custom-wrapper-dashboard">
              <div class="d-flex align-items-center flex-wrap mr-2 text-title">
                <img
                  src="@/assets/images/accountsetup/setting.svg"
                  alt=""
                  class="mr-2"
                />
                {{ lbl['main-menu-setting'] }}
              </div>
            </div>

            <div class="col-xl-12 col-lg-12 col-md-12 wrapper-content-tab">
              <div class="d-flex wrapper-select-header">
                <div
                  v-if="isEdit"
                  class="select-header cursor-pointer"
                  :class="{ active: true }"
                >
                  {{ lbl['account-setup-account-setting'] }}
                </div>
                <div v-else class="select-header" :class="{ active: true }">
                  {{ lbl['account-setup-account-setting'] }}
                </div>
              </div>
            </div>
          </div>

          <div class="px-5">
            <a-form :form="form" @submit="handleSubmit">
              <!-- Business Name -->
              <div class="row d-flex justify-content-between">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                  <a-form-item>
                    <strong class="fz-16">{{
                      lbl['account-setup-your-business-name']
                    }}</strong>
                    <a-input
                      v-decorator="[
                        'business_name',
                        {
                          initialValue: business_name,
                          rules: [
                            {
                              required: true,
                              message: '',
                            },
                          ],
                        },
                      ]"
                      type="text"
                      class="form-control w-100"
                    />
                  </a-form-item>
                </div>
                <div
                  class="
                    col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12
                    d-flex
                    justify-content-end
                  "
                >
                  <span class="mr-3">
                    {{ lbl['setup-header-appid'] }} : {{ appId }}
                  </span>
                  <span>
                    {{ lbl['setup-header-brandid'] }} : {{ brandId }}
                  </span>
                </div>
              </div>

              <!-- Upload Your Logo -->
              <div class="row">
                <div class="col-12">
                  <a-form-item class="field-item">
                    <strong class="fz-16">{{
                      lbl['account-setup-upload-your-logo']
                    }}</strong>
                    <div class="row">
                      <div class="col-12 field-upload">
                        <div
                          class="box-img"
                          :class="{
                            'box-img-border': isPreview,
                            'none-border': imgPreview,
                          }"
                        >
                          <img
                            v-if="!imgPreview"
                            class="w-100"
                            src="@/assets/images/blank-photo.png"
                            alt=""
                          />
                          <img
                            v-else
                            :src="imgPreview"
                            class="w-100 h-100"
                            alt=""
                          />
                        </div>
                        <div class="box-upload">
                          <div
                            class="btn-bg-upload"
                            @click="uploadArtWork($event)"
                          >
                            <input
                              @change="onImgFileChange($event)"
                              ref="uploadImg"
                              type="file"
                              accept="image/jpeg, image/png, image/jpg"
                              hidden
                            />
                            <img
                              src="@/assets/images/accountsetup/account_upload.svg"
                              alt=""
                              width="24"
                              height="24"
                            />
                            <span class="ml-2">
                              {{ lbl['account-setup-upload-image'] }}
                            </span>
                          </div>
                          <div class="box-recommended">
                            <div>
                              {{ lbl['account-setup-the-recommended'] }}
                            </div>
                            <div>
                              {{ lbl['account-setup-upload-file-recommended'] }}
                            </div>
                            <div>
                              {{ sizeUpload }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a-form-item>
                </div>
              </div>

              <!-- Footer Button -->
              <div class="row">
                <div class="col-12">
                  <hr />
                  <b-button
                    variant="warning"
                    class="btn-submit float-right"
                    html-type="submit"
                    ref="submit"
                    type="submit"
                    :disabled="formState"
                  >
                    {{ lbl['account-setup-button-save'] }}
                  </b-button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
    <AlertModal
      :isOpenModal="alertModalSetting.isOpenModal"
      :modalType="alertModalSetting.modalType"
      :title="alertModalSetting.title"
      :details="alertModalSetting.details"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import Mixin from '@/mixin/Mixin'
import Header from '@/components/Layout/Header'
import Cache from '@/helper/Cache.js'
import InfiniteHelper from '@/helper/InfiniteHelper.js'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import fileReader from '@/helper/fileReader'
import Account from '@/helper/AccountHelper.js'
import BzbsAuth from '@/core/Account/service/BzbsAuth'
import Utility from '@/helper/Utility.js'
import AlertModal from '@/components/Modal/Component/alertmodal2'

export default {
  name: 'AccountSetting',
  mixins: [Mixin],
  components: {
    Header,
    AlertModal,
  },
  data: function () {
    return {
      optionBusinessType: [],
      radioCampaignMode: 'point',
      radioLoginType: null,
      isPH: false,
      isTH: false,
      imgPreview: '',
      isPreview: false,
      sizeUpload: '',
      business_name: '',
      business_type: '',
      isEdit: false,
      isModalMode: false,
      isEmailModal: false,
      storeType: '',
      appId: '',
      brandId: '',
      crmPlusRole: '',
      params: {},
      params_reward: {},
      util: Utility,
      confirmModalSetting: {
        title: '',
        detail: '',
        modalType: '',
        backto: '',
        confirm: '',
        onConfirm: null,
        onCancel: null,
        isOpenModal: false,
      },
      alertModalSetting: {
        title: '',
        detail: '',
        modalType: '',
        isOpenModal: false,
      },
      maxlength: 10,
      OwnerContactNumber: null,
      phone: '',
      LineMessageChannelId: '',
      otpDetails: {},
      timeinterval: null,
      baseradioCampaignMode: null,
      isOTPConfirm: true,
      setupNotification: false,
      loginTypeList: [],
      crmPackage: {},
    }
  },
  computed: {
    formState() {
      return !this.isOTPConfirm
    },
    checkLocalTH() {
      if (Cache.get('Locale') == 'th') {
        return true
      } else {
        return false
      }
    },
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'account-setting',
    })
  },
  created() {
    this.handleFooter(true)
    Account.bzbsAnalyticTracking(
      'setting_account_page',
      'setting_account',
      'view_setting',
      'on view',
    )
    this.crmPackage = Account.getCachePackage()
    if (Vue.bzbsConfig.businessRule.webFor.PH) {
      this.isPH = true
    }
    if (Vue.bzbsConfig.businessRule.webFor.TH) {
      this.isTH = true
    }
    this.init()
  },
  methods: {
    init() {
      this.sizeUpload = this.lbl[
        'account-setup-upload-size-recommended'
      ].replace('{0}', Vue.bzbsConfig.businessRule.max.photoSize)
      this.maxlength = Vue.bzbsConfig.businessRule.max.phoneLength
      this.getLoginTypeList()
      this.getBusinessType()
      this.getCRMPlusProfile()
    },
    getLoginTypeList() {
      return new Promise((resolve, reject) => {
        BzbsProfile.getLoginTypeList()
          .then(response => {
            console.log(response.data)
            this.loginTypeList = response.data
            resolve(response)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('getLoginTypeList error', error)
            resolve(error)
            reject(error)
          })
      })
    },
    getCRMPlusProfile() {
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            this.genDataProfile(res.data)
            this.checkAccountSettup(res.data)
            this.handleLoading(false)
            console.log(res)
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)

            console.log('getCRMPlusProfile error', error)
            resolve(error)
          })
      })
    },
    confirmModalAction(
      title,
      details,
      modalType,
      backto,
      isOpenModal,
      onConfirm,
      onCancel,
    ) {
      this.confirmModalSetting.title = title
      this.confirmModalSetting.details = details
      this.confirmModalSetting.modalType = modalType
      this.confirmModalSetting.isOpenModal = isOpenModal
      this.confirmModalSetting.backto = backto
      this.confirmModalSetting.onConfirm = onConfirm
      this.confirmModalSetting.onCancel = onCancel
    },
    alertModalAction(title, details, modalType, isOpenModal) {
      this.alertModalSetting.title = title
      this.alertModalSetting.details = details
      this.alertModalSetting.modalType = modalType
      this.alertModalSetting.isOpenModal = isOpenModal
      if (modalType == 'success' && isOpenModal) {
        setTimeout(() => {
          this.alertModalSetting.isOpenModal = false
        }, 3000)
        setTimeout(() => {
          this.alertModalSetting.title = ''
          this.alertModalSetting.details = ''
          this.alertModalSetting.modalType = ''
        }, 3500)
      } else {
        if (isOpenModal)
          setTimeout(() => {
            this.alertModalSetting.isOpenModal = false
          }, 3000)
        setTimeout(() => {
          this.alertModalSetting.title = ''
          this.alertModalSetting.details = ''
          this.alertModalSetting.modalType = ''
        }, 3500)
      }
    },
    alertModalUpdateSyncAction(title, details, modalType, isOpenModal) {
      this.alertModalSetting.title = title
      this.alertModalSetting.details = details
      this.alertModalSetting.modalType = modalType
      this.alertModalSetting.isOpenModal = isOpenModal
      if (modalType == 'success' && isOpenModal) {
        setTimeout(() => {
          this.alertModalSetting.isOpenModal = false
        }, 3000)
        setTimeout(() => {
          this.alertModalSetting.title = ''
          this.alertModalSetting.details = ''
          this.alertModalSetting.modalType = ''
          this.$router.go()
        }, 3500)
      } else {
        if (isOpenModal)
          setTimeout(() => {
            this.alertModalSetting.isOpenModal = false
          }, 3000)
        setTimeout(() => {
          this.alertModalSetting.title = ''
          this.alertModalSetting.details = ''
          this.alertModalSetting.modalType = ''
          this.$router.push({ name: 'BranchSetting' })
        }, 3500)
      }
    },
    checkAccountSettup(data) {
      if (data.LoginType.toLowerCase() == 'line') {
        if ((data.LiffLanding || data.LiffId) && data.AppId) {
          this.setupNotification = false
        } else {
          this.setupNotification = true
        }
      } else {
        if (data.AppId) {
          this.setupNotification = false
        } else {
          this.setupNotification = true
        }
      }
    },
    genDataProfile(data) {
      this.storeType = data.StoreType
      this.imgPreview = data.LogoUrl + '?v=' + new Date().getTime()
      this.business_name = data.BusinessName
      this.business_type = data.BusinessType
      this.params.agencyId = data.SponsorId
      this.params_reward.agencyId = data.SponsorId
      this.business_name ? (this.isEdit = true) : (this.isEdit = false)
      this.OwnerContactNumber = data.OwnerContactNumber
      this.LineMessageChannelId = data.LineMessageChannelId

      if (data.Stores) {
        let branch = _.map(data.Stores, e => {
          return {
            id: e.ID,
            name: e.Name,
            contact_number: e.ContactNumber,
            latitude: e.Latitude,
            longtitude: e.Longtitude,
            isheadoffice: e.IsHeadOffice,
          }
        })

        this.params.branch = JSON.stringify(branch)
      }

      this.brandId = data.EwalletBrandId
      this.appId = data.AppId
      var CRMUserCookie = Cache.get('CRMUser')
      CRMUserCookie.AppId = data.AppId
      Cache.set('CRMUser', CRMUserCookie)
      this.crmPlusRole = Cache.get('CRMUser').CrmPlusRole
    },
    selectLoginType(id) {
      this.radioLoginType = id
    },
    formatCode(value) {
      if (value) {
        if (value.length > 10) {
          let outstr = value.substr(0, 1) + 'xxxxxxx' + value.substr(8, 3)
          console.log(outstr)
          return outstr
        } else {
          let outstr = value.substr(0, 1) + 'xxxxxx' + value.substr(7, 3)
          console.log(outstr)
          return outstr
        }
      } else {
        return ''
      }
    },
    replaceContact(value) {
      try {
        return value.replace(/[^0-9]/g, '')
      } catch {
        return value
      }
    },
    getOTP() {
      if (this.OwnerContactNumber != null && this.OwnerContactNumber != '') {
        this.handleLoading(true)
        let params = {
          contact_number: this.replaceContact(this.OwnerContactNumber),
          app_id: Vue.bzbsConfig.client_id,
          channel: 'update',
        }
        BzbsAuth.apiRequestOTP(params)
          .then(res => {
            this.handleLoading(false)
            this.otpDetails = res.data
            this.isModalMode = true
            Account.bzbsAnalyticTracking(
              'setting_account_page',
              'setting_account',
              'click_change_campaign_mode_done',
              'on click',
            )
            Account.bzbsAnalyticTracking(
              'setting_account_page',
              'setting_account',
              'view_change_campaign_mode_otp',
              'on view',
            )
            this.countDownTimer()
          })
          .catch(() => {
            this.handleLoading(false)
          })
      } else {
        this.alertModalAction(
          this.lbl['alert-box-error-header'],
          this.lbl['user-permissions-phonevalidate'],
          'error',
          true,
        )
      }
    },
    confirmOTP(otpcode) {
      this.handleLoading(true)
      let params = {
        contact_number: this.replaceContact(this.OwnerContactNumber),
        app_id: Vue.bzbsConfig.client_id,
        channel: 'update',
        otp: otpcode,
        refcode: this.otpDetails.refcode,
      }
      BzbsAuth.apiConfirmOTP(params)
        .then(() => {
          this.handleLoading(false)
          this.isModalMode = false
          this.isOTPConfirm = true
          this.postReward(true)
        })
        .catch(error => {
          this.handleLoading(false)
          this.isOTPConfirm = false
          this.alertModalAction(
            this.lbl['alert-box-error-header'],
            error.response.data.error.message,
            'error',
            true,
          )
        })
    },
    gaback() {
      this.isModalMode = false
      this.confirmModalAction(
        'Change campaign mode ?',
        'By changeing campaign mode will be effect to all of your consumer.',
        'warning',
        this.lbl['confirm-box-cancel-button'],
        true,
        () => {
          this.getOTP()
          this.confirmModalSetting.isOpenModal = false
        },
        () => {
          this.confirmModalSetting.isOpenModal = false
          this.radioCampaignMode = this.baseradioCampaignMode
        },
      )
    },
    countDownTimer() {
      if (this.timeinterval) clearInterval(this.timeinterval)
      this.timeinterval = setInterval(() => {
        if (this.otpDetails.expireinseconds > 0) {
          this.otpDetails.expireinseconds -= 1
        } else {
          clearInterval(this.timeinterval)
        }
      }, 1000)
    },
    isJsonString(str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
    getBusinessType() {
      this.optionBusinessType = InfiniteHelper.businessTypeList()
    },
    uploadArtWork() {
      this.$refs.uploadImg.value = null
      this.$refs.uploadImg.click()
    },
    onImgFileChange(event) {
      var image = event.target.files[0]
      var allowType = ['image/jpeg', 'image/jpg', 'image/png']
      var maxSize = 1024 * 1024 * Vue.bzbsConfig.businessRule.max.photoSize
      var isCorrectType = allowType.includes(image.type)
      var isCorrectSize = image.size <= maxSize
      var lblmaximumConfig = this.lbl[
        'image-recommended-message-setting'
      ].replace('{0}', Vue.bzbsConfig.businessRule.max.photoSize)
      if (isCorrectType && isCorrectSize && this.imgPreview) {
        this.isPreview = false
        var afterDot = image.name.substr(0, image.name.indexOf('.'))
        var nonSpacial = afterDot.replace(/[^a-zA-Z0-9]/g, '')
        var newFileName = nonSpacial.replace(/\s/g, '_')
        var resultFile = new File([image], newFileName, {
          type: image.type,
        })
        this.imgPreview = URL.createObjectURL(resultFile)
      } else {
        this.alertModalAction(
          this.lbl['alert-box-error-header'],
          lblmaximumConfig,
          'error',
          true,
        )
      }
    },
    changeRadioMode(e) {
      if (
        this.baseradioCampaignMode !== e.target.value &&
        this.storeType != null
      ) {
        this.isOTPConfirm = false
      } else {
        this.isOTPConfirm = true
      }
      this.radioCampaignMode = e.target.value
      if (this.isEdit) {
        if (!this.isPH) {
          if (this.OwnerContactNumber) {
            if (this.baseradioCampaignMode != e.target.value) {
              Account.bzbsAnalyticTracking(
                'setting_account_page',
                'setting_account',
                'view_change_campaign_mode',
                'on view',
              )
              this.confirmModalAction(
                this.lbl['otptitle'],
                this.lbl['otpsubtitle'],
                'warning',
                this.lbl['confirm-box-cancel-button'],
                true,
                () => {
                  this.getOTP()
                  this.confirmModalSetting.isOpenModal = false
                  Account.bzbsAnalyticTracking(
                    'setting_account_page',
                    'setting_account',
                    'click_change_campaign_mode_next',
                    'on click',
                  )
                },
                () => {
                  this.isOTPConfirm = true
                  this.radioCampaignMode = this.baseradioCampaignMode
                  this.confirmModalSetting.isOpenModal = false
                  Account.bzbsAnalyticTracking(
                    'setting_account_page',
                    'setting_account',
                    'click_change_campaign_mode_cancel',
                    'on click',
                  )
                },
              )
            }
          } else {
            this.confirmModalAction(
              this.lbl['otpwarning'],
              this.lbl['otpwarningtext'],
              'warning',
              this.lbl['confirm-box-cancel-button'],
              true,
              () => {
                this.$router.push({ name: 'UserPermission' })
                this.confirmModalSetting.isOpenModal = false
              },
              () => {
                this.confirmModalSetting.isOpenModal = false
              },
            )
          }
        } else {
          this.isEmailModal = true
        }
      }
    },
    changeCampaignMode(res) {
      console.log('changeCampaignMode : ', res)
      this.isModalMode = false
      if (res) {
        console.log('changeCampaignMode true')
        // this.storeType = this.radioCampaignMode
      } else {
        console.log('changeCampaignMode false')
        // this.radioCampaignMode = this.storeType
      }
      console.log('storeType :: ', this.storeType)
    },
    ChamgeEmailmodal(res) {
      console.log('ChamgeEmailmodal : ', res)
      this.isEmailModal = false
      if (res) {
        console.log('ChamgeEmailmodal true')
        this.storeType = this.radioCampaignMode
        this.isOTPConfirm = false
      } else {
        console.log('ChamgeEmailmodal false')
        this.radioCampaignMode = this.storeType
        this.isOTPConfirm = true
      }
      console.log('storeType :: ', this.storeType)
    },
    handleSubmit(e) {
      e.preventDefault()
      Account.bzbsAnalyticTracking(
        'setting_account_page',
        'setting_account',
        'click_setting_account_save',
        'on click',
      )
      if (!this.imgPreview) {
        this.isPreview = true
      }
      this.form.validateFields((err, values) => {
        if (!err && this.imgPreview) {
          console.log(values)
          fileReader.getBaseImage(this.imgPreview).then(item => {
            this.params.logo = fileReader.blobToFile(item, 'LogoUrl')
            this.params.business_name = values.business_name
            this.params.business_type = values.business_type
            this.handleLoading(true)

            return new Promise(resolve => {
              BzbsProfile.postAccountSetting(this.params)
                .then(res => {
                  this.setLogoUrl(res)
                  var roleType
                  if (this.crmPackage && this.crmPackage.PackageId) {
                    if (this.crmPackage.PackageId.includes('hiveplus')) {
                      roleType = 'HivePlus'
                    } else if (this.crmPackage.PackageId.includes('benefit')) {
                      roleType = 'BEEsBenefit'
                    } else {
                      roleType = 'CRMPlus'
                    }
                  } else {
                    roleType = 'CRMPlus'
                  }
                  if (this.appId) {
                    Account.syncApplication(
                      this.appId,
                      res.data.store.BusinessName,
                      res.data.store.LogoUrl,
                      roleType,
                      res.data.store.Email,
                    )
                      .then(() => {
                        this.handleLoading(false)
                        this.alertModalUpdateSyncAction(
                          this.lbl['alert-box-success-header'],
                          '',
                          'success',
                          true,
                        )
                        resolve(res.data)
                      })
                      .catch(error => {
                        this.handleLoading(false)
                        this.alertModalUpdateSyncAction(
                          this.lbl['alert-box-error-sync-header'],
                          error.response.data.error.message,
                          'error',
                          true,
                        )
                        resolve(error)
                      })
                  } else {
                    this.handleLoading(false)
                    this.$router.push({ name: 'BranchSetting' })
                  }
                })
                .catch(error => {
                  this.handleLoading(false)
                  this.alertModalAction(
                    this.lbl['alert-box-error-header'],
                    error.response.data.error.message,
                    'error',
                    true,
                  )
                  resolve(error)
                })
            })
          })
        }
      })
    },
    setLogoUrl(res) {
      console.log('setLogoUrl : ', res.data.store.LogoUrl)
      if (res.data.store.LogoUrl) {
        var img = res.data.store.LogoUrl + '?v=' + new Date().getTime()
        Account.updateLogoUrl(img)
      }
    },
    postReward(change) {
      console.log('postReward')
      this.params_reward.type = this.radioCampaignMode
      return new Promise(resolve => {
        BzbsProfile.postReward(this.params_reward)
          .then(res => {
            if (res.data.Success) {
              if (change) {
                this.alertModalAction(
                  this.lbl['alert-box-success-header'],
                  '',
                  'success',
                  true,
                )
              } else {
                this.$router.push({ name: 'BranchSetting' })
              }
            }
            this.handleLoading(false)
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            this.alertModalAction(
              this.lbl['alert-box-error-header'],
              error.response.data.error.message,
              'error',
              true,
            )
            resolve(error)
          })
      })
    },
    nextBranchSetting() {
      Account.bzbsAnalyticTracking(
        'setting_account_page',
        'setting_account',
        'click_setting_account_branch_tab',
        'on click',
      )
      this.$router.push({ name: 'BranchSetting' })
    },
    gotoConnectSetting() {
      Account.bzbsAnalyticTracking(
        'setting_account_page',
        'setting_account',
        'click_setting_account_line_connect_tab',
        'on click',
      )
      this.$router.push({ name: 'ConnectSetting' })
    },
    gotoConnectFacebook() {
      Account.bzbsAnalyticTracking(
        'setting_account_page',
        'setting_account',
        'click_setting_account_connect_facebook_tab',
        'on click',
      )
      this.$router.push({ name: 'ConnectFacebook' })
    },
  },
}
</script>
<style lang="scss" scoped>
.telcontainer {
  label {
    font-size: 14px;
    font-weight: 500;
  }
  .inputcontainer {
    position: relative;
    img {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      z-index: 1;
    }
  }
}

.dot-notification {
  position: absolute;
  right: -8px;
  top: 0;
}
</style>
<style lang="scss">
@import '../styles/accountsetup_main.scss';
.telcontainer {
  .ant-input {
    padding-left: 40px;
  }
  .error {
    .ant-input {
      border: 1px solid red;
    }
  }
}

.account-setting-page {
  .radio-group-container {
    .wrapper-radio-custom {
      width: 80%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 16px;

      .radio-main {
        cursor: pointer;
        flex: 1;
        min-width: 100px;
        padding: 16px;
        border-radius: 4px;
        border: solid 1px #ffffff;
        background-color: #fafafa;
        margin: 0;
        white-space: normal;
        color: $color-grey-75;

        .mt-10 {
          margin-top: 10px;
        }
        .sub-description {
          font-size: 12px;
          color: $text-light-gray;
        }
        strong {
          color: $color-grey-42;
          margin-bottom: 10px;
        }
        .login-type-title,
        .login-type-detail {
          white-space: wrap;
          line-height: 2;
        }
      }
      .active {
        border: solid 1px $primary;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
      }
      .disabled {
        cursor: not-allowed;
      }
    }
  }

  .login-type-card {
    .logintype-icon {
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }
  }
}
</style>
